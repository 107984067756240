import * as React from 'react'
import LineItem from '../LineItem/LineItem'
import { StoreContext } from '../../../context/store-context'
import { Link } from 'gatsby'
import { formatPrice } from '../../../utils/formatPrice'
import Toast from '../Toast/Toast'

export const cartTableHeaders = ['Image', 'Product', 'Price', 'Qty.', 'Total']

const CartPage = () => {
  const { checkout, loading } = React.useContext(StoreContext)

  const isCartEmpty = checkout.lines.nodes.length === 0

  // Open the Shopify checkout process in a new window/tab
  const handleCheckout = () => {
    window.open(checkout.checkoutUrl)
  }

  return (
    <div className="standard-page-section">
      <Toast show={loading}>
        <p>Updating...</p>
      </Toast>
      <h1 className="text-center">Cart</h1>
      {isCartEmpty ? (
        <div className="flex flex-col justify-center items-center text-center sm:text-left my-12 px-4">
          <p className="text-lg sm:text-xl mb-12">
            Your cart is empty! Having trouble finding what you&apos;re looking
            for?
          </p>
          <Link className="btn btn-2 btn-xs" to="/shop/products">
            Keep shopping
          </Link>
        </div>
      ) : (
        <>
          <div className="w-full overflow-x-auto pl-4 md:pl-0">
            <table className="CartTable" data-testid="cart-table">
              <thead>
                <tr className="uppercase text-sm text-left">
                  {cartTableHeaders.map((tableHeader) => {
                    return <th key={tableHeader}>{tableHeader}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {checkout.lines.nodes.map((item) => (
                  <LineItem item={item} key={item.id} />
                ))}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="font-bold text-xl">Subtotal</td>
                  <td>
                    {formatPrice(
                      checkout.cost.totalAmount.currencyCode,
                      parseFloat(checkout.cost.totalAmount.amount)
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            <p className="text-center">
              Taxes and shipping calculated at checkout.
            </p>
          </div>
          <div className="flex justify-center mt-12 gap-4">
            <Link className="btn btn-2 btn-xs" to="/shop/products">
              Keep shopping
            </Link>
            <button className="btn btn-1 btn-xs" onClick={handleCheckout}>
              Go to checkout
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default CartPage
