import * as React from 'react'
import debounce from 'lodash.debounce'
import { StoreContext } from '../../../context/store-context'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'
import { formatPrice } from '../../../utils/formatPrice'
import NumericInput from '../../NumericInput/NumericInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link, graphql, useStaticQuery } from 'gatsby'

export interface ILineItemProps {
  id: string
  quantity?: number
  merchandise?: {
    title: string
    image: {
      url: string
    }
    price?: {
      currencyCode: string
      amount: string
    }
    product: {
      handle: string
      title: string
    }
    id: string
  }
}

export default function LineItem({ item }: { item: ILineItemProps }) {
  const { removeLineItem, checkout, updateLineItem } =
    React.useContext(StoreContext)

  const { allShopifyProductVariant } = useStaticQuery(query)

  const quantityInStock = allShopifyProductVariant.nodes.find(
    (variant: { storefrontId?: string }) =>
      variant.storefrontId === item.merchandise?.id
  ).inventoryQuantity

  const [quantity, setQuantity] = React.useState<number>(
    item.quantity ? item.quantity : 1
  )

  const variantImage = {
    ...item.merchandise?.image,
    originalSrc: item.merchandise?.image.url,
    altText: item.merchandise?.title,
  }

  const price = formatPrice(
    String(item.merchandise?.price?.currencyCode),
    Number(item.merchandise?.price?.amount)
  )

  const subtotal = formatPrice(
    String(item.merchandise?.price?.currencyCode),
    Number(item.merchandise?.price?.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) =>
      item.merchandise?.id
        ? updateLineItem(checkout.id, item.id, item.merchandise?.id, value)
        : null,
    300
  )

  const debouncedUli = React.useCallback((value: number) => uli(value), [])

  const handleQuantityChange = (value: string | number) => {
    if (value !== '' && Number(value) < 1) {
      return
    }
    if (!isNaN(Number(value)) && Number(value) <= quantityInStock) {
      setQuantity(Number(value))
      if (Number(value) >= 1) {
        debouncedUli(Number(value))
      }
    }
  }

  function handleIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function handleDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        crop: 'contain',
        width: 160,
        height: 160,
      }),
    [variantImage.url]
  )

  return (
    <tr data-testid="cart-line-item">
      <td>
        {image && (
          <div className="w-24 bg-grey-300 p-1">
            <Link
              aria-label={item.merchandise?.title}
              to={`/shop/products/${item.merchandise?.product.handle}`}
              data-testid="line-item-image-link"
            >
              <GatsbyImage
                key={variantImage.url}
                image={image}
                alt={variantImage.altText ?? String(item.merchandise?.title)}
              />
            </Link>
          </div>
        )}
      </td>
      <td>
        <h2 className="text-lg">{item.merchandise?.product.title}</h2>
        <h3 className="text-base font-normal">
          {item.merchandise?.title === 'Default Title'
            ? ''
            : item.merchandise?.title}
        </h3>
        <div className="mt-2">
          <button onClick={handleRemove}>
            <FontAwesomeIcon
              className="text-error-600"
              icon={faTrash as IconProp}
            />{' '}
            Remove
          </button>
        </div>
      </td>
      <td data-testid="item-price">{price}</td>
      <td>
        <NumericInput
          label={`${item.merchandise?.title} quantity`}
          testId={`${item.merchandise?.product.handle}-numeric-input`}
          value={quantity}
          aria-label="Quantity"
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          onChange={(e: { currentTarget: { value: string | number } }) =>
            handleQuantityChange(e.currentTarget.value)
          }
          maxValue={quantityInStock}
        />
      </td>
      <td data-testid="item-total">{subtotal}</td>
    </tr>
  )
}

const query = graphql`
  query GetProductVariants {
    allShopifyProductVariant(filter: { inventoryQuantity: { gt: 0 } }) {
      nodes {
        storefrontId
        inventoryQuantity
      }
    }
  }
`
